@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .table-content {
    @apply /* border */
        /* border-gray3 */
        /* dark:border-gray8 */
        rounded
        mt-4;
  }

  .table-content__table-view {
    @apply relative  
        /* overflow-x-auto  */
        sm:rounded-lg;
  }

  .table-content__table {
    @apply w-full 
        text-left 
        text-gray5
        rtl:text-right 
        dark:text-gray4;
  }

  .table-content__table-header {
    @apply text-gray7
        uppercase 
        bg-gray1
        dark:bg-primaryDark
        dark:text-gray4;
  }

  .table-content__table-header th {
    @apply normal-case
    /* bg-white */
        px-4 
        py-3;
  }

  .table-content__table-body tr {
    @apply bg-white 
        dark:bg-primaryDark
        dark:border-gray7
        z-10;
  }

  .table-content__table-body tr:nth-child(even) {
    @apply bg-gray1
        dark:bg-gray9;
  }

  .table-content__table-body td {
    @apply px-4
        py-2;
  }

  .table-content__table-sort {
    @apply flex 
        items-center;
  }

  .table-content__table-action {
    @apply flex
        justify-end;
  }

  .table-content__table-action-btn {
    @apply text-gray5
        p-2
        mr-1
        mt-1
        rounded
        border
        border-gray3
        dark:border-gray8
        inline-block
        hover:bg-gray2
        dark:bg-gray9
        dark:hover:bg-gray8;
  }

  /* New styles for dropdown menu */
  .dropdown {
    position: relative;
  }

  .dropdown > .table-content__table-action-btn + div {
    position: absolute;
    /* top: calc(100% + 0.5px);  */
    right: 0;
    z-index: 10;
    background-color: #fff; /* Adjust background color as needed */
    border: 1px solid #ccc; /* Adjust border color as needed */
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Adjust shadow as needed */
  }

  .open-upwards {
    bottom: calc(100% + 0.5px);
  }

  .open-downwards {
    top: calc(100% + 0.5px);
  }

  .reconciliation-report-detail-row {
    background-color: #f3f4f6 !important;
  }

  .reconciliation-report-summary-row {
    background-color: white !important;
  }
}
