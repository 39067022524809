@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    .login-page {
        @apply
        lg:flex
        bg-gradient-to-bl
        from-primaryDefault
        to-secondary1
        h-screen;
    }

    .login-page__left {
        @apply
        h-1/3
        lg:h-screen
        flex
        flex-col
        p-12
        xl:p-56
        xl:w-2/3
        lg:w-1/2
        justify-between
        items-start;
    }

    .login-page__right {
        @apply
        flex
        h-2/3
        lg:h-screen
        items-center
        text-gray9
        dark:text-white
        bg-white
        dark:bg-gray9
        2xl:p-24
        xl:p-12
        md:p-24
        p-6
        xl:w-1/3
        lg:w-1/2
        align-middle;
    }
}