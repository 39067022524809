@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .sidebar-nav {
    @apply fixed 
    top-0 
    left-0 
    z-50
    w-64 
    h-screen 
    transition-transform 
    -translate-x-full 
    sm:translate-x-0;
  }
  .sidebar {
    @apply h-full 
    border-r
    border-r-gray2
    dark:border-r-gray8 
    bg-white 
    dark:bg-primaryDark;
  }

  .sidebar__logo {
    @apply p-4;
  }

  .sidebar__menu {
    @apply p-3;
  }

  .sidebar__menu-item-link {
    @apply flex 
    items-center 
    p-3 
    rounded-lg
    text-gray7
    dark:text-white
    hover:bg-primaryLight
    dark:hover:bg-gray9;
  }

  .sidebar__menu-item-link.active {
    @apply bg-primaryLight;
  }

  .sidebar__menu-item-link--text {
    @apply flex-1 
    ms-1 
    text-left 
    rtl:text-right 
    whitespace-nowrap
    text-gray7
    dark:text-white
    dark:hover:text-white;
  }
}
