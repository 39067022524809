@tailwind base;
@tailwind components;
@tailwind utilities;

/* @import './components/sidebar/sidebar.css'; */
@import './components/sidebar/sidenavbar.css';

body {
  @apply text-lg;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.react-datepicker-wrapper {
  width: 100% !important;
}

.react-datepicker-popper {
  z-index: 10 !important;
}
