@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .table-content__search {
    @apply pt-4
        px-4;
  }

  .table-content__search-label {
    @apply mb-2 
        text-sm 
        font-medium 
        text-gray9 
        sr-only
        dark:text-white;
  }

  .table-content__search-content {
    @apply relative;
  }

  .table-content__search-icon {
    @apply absolute 
        inset-y-0 
        flex 
        items-center
        pointer-events-none
        start-0
        ps-3;
  }

  .table-content__search-input {
    @apply block  
        w-full 
        p-4 
        text-gray9
        border 
        border-white 
        rounded-lg
        ps-10 
        bg-gray1 
        dark:bg-gray9 
        dark:border-gray8
        dark:placeholder-gray4
        dark:text-white 
        focus:ring-2 
        focus:outline-none 
        focus:ring-primaryFocus;
  }

  .table-content__search-btn {
    @apply text-white 
        absolute 
        end-2.5 
        bottom-2.5 
        font-medium 
        rounded-lg 
        px-4 
        py-2 
        focus:ring-2 
        focus:outline-none 
        focus:ring-primaryFocus 
        bg-primaryDefault 
        hover:bg-primaryHover 
        dark:bg-primaryDefault 
        dark:hover:bg-primaryHover 
        dark:focus:ring-primaryFocus;
  }

  .table-content__adv-search {
    @apply relative
        mb-4
        pt-4
        px-4;
  }

  .table-content__adv-search-content {
    @apply p-2
        rounded-lg
        bg-gray1
        dark:bg-gray9
        border
        border-gray1
        dark:border-gray8;
  }

  .table-content__adv-search-content .table-content__search-btn {
    @apply relative;
  }

  .table-content__adv-search-content .table-content__search-input {
    @apply bg-white
        dark:bg-gray9;
  }

  .table-content__adv-search-box {
    @apply p-4
        m-4
        border
        border-gray2
        dark:border-gray8
        bg-white
        dark:bg-gray9
        rounded-lg
        shadow-lg
        dark:shadow-black
        absolute
        left-0
        top-16
        right-0
        z-10;
  }
}
