@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    .empty-content {
        @apply
        text-center
        text-gray4
        dark:text-gray1
        bg-gray1
        rounded-lg
        mt-4
        lg:p-40
        md:p-10
        py-16;
    }

    .empty-contnet__icon {
        @apply
        flex
        justify-center
        py-6;
    }

    .empty-content__action-area {
        @apply
        flex
        justify-center;
    }

    .empty-content__action-area-button {
        @apply
        w-60
        py-6;
    }
}