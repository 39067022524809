@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    .table-action-area {
        @apply
        flex
        justify-between
        items-center
        mt-4
        mx-4
        py-3
        text-gray6
        dark:text-gray3
        border
        border-t
        border-b
        border-l-0
        border-r-0
        border-gray2
        dark:border-gray8;
    }

    .table-action-area__left-actions {
        @apply
        flex
        gap-4;
    }

    .table-action-area__right-actions {
        @apply
        relative
        flex
        gap-2;
    }
}

