@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    .main-content__page-topic {
        @apply
        flex 
        items-center 
        justify-between;
    }

    .breadcrumb {
        @apply
        flex;
    }

    .breadcrumb__list {
        @apply
        inline-flex 
        items-center 
        space-x-1
        md:space-x-2 
        rtl:space-x-reverse;
    }

    .breadcrumb__list-item {
        @apply
        inline-flex 
        items-center;
    }

    .breadcrumb__list-item-link {
        @apply
        inline-flex 
        items-center 
        text-sm 
        text-primaryDefault
        hover:text-primaryDefault 
        dark:text-primaryDefault
        dark:hover:text-white;
    }

    .breadcrumb__list-inactive-item--text {
        @apply
        text-sm 
        text-gray6
        ms-1 
        md:ms-2 
        dark:text-gray4;
    }

    .main-content__page-topic-filter {
        @apply
        inline-flex 
        rounded-md 
        shadow-sm;
    }

    .main-content__page-topic-filter .primary-btn {
        @apply
        flex 
        items-center 
        px-4 
        py-2 
        font-medium 
        text-white 
        rounded-lg
        bg-primaryDefault 
        border-primaryDefault 
        hover:bg-primaryHover 
        hover:text-white 
        focus:text-white
        focus:hover:text-white 
        focus:z-10 
        focus:ring-2 
        focus:ring-primaryFocus;
    }
}