@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .stepper {
    @apply overflow-auto
        mb-6;
  }
  .stepper__list {
    @apply flex 
        items-center 
        w-full 
        text-center 
        text-gray5 
        dark:text-gray1 
        sm:text-base;
  }

  .stepper__list-item {
    @apply flex 
        md:w-full 
        items-center 
        text-sm
        text-gray9
        dark:text-white 
        sm:after:content-[''] 
        after:w-full 
        after:h-[1px] 
        after:border-b
        after:border-gray3 
        after:border-l
        after:hidden 
        sm:after:inline-block 
        after:mx-6 
        xl:after:mx-10 
        dark:after:border-gray7;
  }

  .stepper__list-item-content {
    @apply block 
        after:content-['/'] 
        sm:after:hidden 
        after:mx-2 
        after:text-gray2 
        dark:after:text-gray5;
  }

  .stepper__list-item-content-num {
    @apply block
        w-6
        h-6
        rounded-full
        border
        border-gray3
        dark:border-gray7
        m-auto
        mb-1
        text-sm;
    cursor: pointer;
  }

  .stepper__list-item-content-num.active {
    @apply bg-primaryDefault
        text-white;
  }
}
