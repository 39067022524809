@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .main-content-header {
    @apply flex;
  }

  .main-content-header__global-search {
    @apply py-4 
        xl:w-[95%]
        lg:w-[90%]
        md:w-[75%];
  }

  .main-content-header__global-search-label {
    @apply mb-2 
        text-sm 
        font-medium 
        text-gray9 
        sr-only
        dark:text-white;
  }

  .main-content-header__global-search-content {
    @apply relative;
  }

  .main-content-header__global-search-icon {
    @apply absolute 
        inset-y-0 
        flex 
        items-center
        pointer-events-none
        start-0
        ps-3;
  }

  .main-content-header__global-search-input {
    @apply block 
        w-full 
        p-4 
        text-gray9
        border 
        border-white 
        rounded-lg
        ps-10 
        bg-gray1 
        dark:bg-primaryDarkLight
        dark:border-gray8
        dark:placeholder-gray4
        dark:text-white 
        focus:ring-2 
        focus:outline-none 
        focus:ring-primaryFocus;
  }

  .main-content-header__global-search-content [type='search'] {
    @apply block 
        w-full 
        p-4 
        text-gray9
        border 
        border-white
        focus:border-primaryDefault 
        rounded-lg
        ps-10 
        bg-gray1 
        dark:bg-gray9 
        dark:border-gray8
        dark:placeholder-gray4
        dark:text-white 
        focus:ring-2 
        focus:outline-none 
        focus:ring-primaryFocus;
  }

  .main-content-header__global-search-btn {
    @apply text-primaryDefault
        dark:text-white
        hover:text-white
        dark:hover:text-white 
        absolute 
        end-2.5 
        bottom-2 
        font-medium 
        rounded-lg 
        px-4 
        py-2
        border
        border-primaryDefault 
        focus:ring-2 
        focus:outline-none 
        focus:ring-primaryFocus 
        bg-white 
        hover:bg-primaryHover 
        dark:bg-gray9 
        dark:hover:bg-primaryHover 
        dark:focus:ring-primaryFocus;
  }

  .main-content-header__nav {
    @apply flex 
        items-center 
        justify-end
        pl-4
        py-4;
  }

  .main-content-header__nav-list {
    @apply flex 
        items-center 
        justify-end 
        gap-2 
        p-0 
        m-0;
  }

  .main-content-header__nav-btn {
    @apply text-gray6
        dark:text-white
        bg-gray1
        hover:bg-gray1
        focus:ring-2
        focus:outline-none 
        focus:ring-primaryFocus
        font-medium 
        rounded-full
        p-2.5
        text-center 
        inline-flex 
        items-center 
        dark:bg-gray8
        dark:hover:bg-gray8
        dark:focus:ring-primaryFocus;
  }

  .main-content-header__nav-user-menu {
    @apply relative 
        inline-block 
        text-left;
  }

  .main-content-header__nav-user-menu-btn {
    @apply w-full 
        mt-1
        inline-flex
        justify-center 
        gap-x-1.5 
        rounded-full
        bg-white 
        text-sm 
        font-semibold 
        text-gray9
        focus:ring-2
        focus:outline-none 
        focus:ring-primaryFocus
        dark:focus:ring-primaryFocus;
  }

  .main-content-header__nav-user-dropdown {
    @apply absolute 
        right-0 
        z-10 
        /* hidden  */
        w-56 
        mt-2 
        origin-top-right 
        bg-white 
        rounded-md 
        shadow-lg
        dark:bg-gray9
        ring-1 
        ring-black 
        ring-opacity-5 
        focus:outline-none;
  }

  .main-content-header__nav-user-dropdown-item {
    @apply flex 
        block 
        w-full 
        gap-2 
        px-4 
        py-2 
        text-gray6
        dark:text-white 
        hover:bg-primaryLight 
        dark:hover:bg-gray8;
  }
}
