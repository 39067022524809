@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .right-sheet {
    @apply fixed
        top-0
        right-0
        bottom-0
        bg-white
        dark:bg-primaryDark
        dark:shadow-black
        shadow-xl;
  }

  .right-sheet.rightsheet-sm {
    @apply z-20
        left-1/2;
  }

  .right-sheet.rightsheet-lg {
    @apply left-64;
  }

  .right-sheet__header {
    @apply py-4
        px-8
        flex
        justify-between
        items-center
        border-b
        border-gray2
        dark:border-gray8;
  }

  .right-sheet-lg__header {
    @apply py-4
        px-8
        border-b
        border-gray2
        dark:border-gray8;
  }

  .right-sheet__header-close-btn {
    @apply text-gray5
        p-2
        mr-1
        mt-1
        rounded
        border
        border-gray3
        dark:border-gray8
        inline-block
        hover:bg-gray2
        dark:bg-gray9
        dark:hover:bg-gray8
        cursor-pointer;
  }

  .right-sheet__body {
    @apply p-8
        min-h-[calc(100vh-146px)]
        max-h-[calc(100vh-146px)]
        overflow-auto;
  }

  .right-sheet__body.has-subcontent {
    @apply min-h-[calc(100vh-180px)] max-h-[calc(100vh-180px)];
  }

  .right-sheet__footer {
    @apply px-8 py-4
        border-t
        border-gray2
        dark:border-gray8;
  }

  .date-picker input {
    @apply bg-gray1 
          border 
          border-gray2 
          text-gray9 
          text-sm 
          rounded-lg
          focus:ring-1
          focus:ring-primaryDefault 
          focus:border-primaryDefault 
          outline-0
          block 
          w-full 
          p-2.5
          pl-10 
          dark:bg-gray9 
          dark:border-gray8
          dark:placeholder-gray4
          dark:text-white 
          dark:focus:ring-primaryDefault
          dark:focus:border-primaryDefault;
  }
}
