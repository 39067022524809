@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .pagination {
    @apply inline-flex 
        -space-x-px 
        px-4
        text-sm;
  }

  .pagination-link {
    @apply flex 
        items-center 
        justify-center 
        px-3 
        h-8 
        leading-tight 
        text-gray5
        bg-white 
        border 
        border-gray2
        hover:bg-gray1
        hover:text-primaryDefault
        dark:bg-gray9
        dark:border-gray9
        dark:text-gray4
        dark:hover:bg-gray8
        dark:hover:text-white;
  }

  .pagination-link.active {
    @apply text-primaryDefault
        bg-gray1
        dark:bg-gray8;
  }

  .pagination li:first-child .pagination-link {
    @apply rounded-s-md;
  }

  .pagination li:last-child .pagination-link {
    @apply rounded-e-md;
  }
}
