@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  body {
    @apply text-sm
        bg-white
        dark:bg-primaryDark;
  }

  .main-content {
    @apply h-screen 
        md:ml-64
        px-4;
  }

  .sub-heading {
    @apply text-gray9
        dark:text-white
        font-bold
        text-sm;
  }

  .heading {
    @apply text-gray9
        dark:text-white
        font-bold
        text-lg;
  }

  .xl-heading {
    @apply text-gray9
        dark:text-white
        font-bold
        text-2xl;
  }

  hr {
    @apply border-gray2
      dark:border-gray8
      !my-2;
  }

  .link {
    @apply hover:text-primaryDefault;
  }

  .date_range {
    background-color: white; /* Ensure it's visible */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Optional: add shadow for better visibility */
    border-radius: 8px; /* Optional: round corners */
    margin-top: 5px;
  }
}
